import { useContext, useEffect, useRef } from 'react';
import './Channel.css';
import { motion, useAnimation, useInView } from 'framer-motion';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/marketing-page/channel', false, /item\d+\.webp$/));

const screenVariants = {
    hidden: (i) => ({
        x: 0
    }),
    visible: (i) => ({
        transition: {
            duration: 1.25, // Increased duration for smoother animation
            delay: i * 0.1, // Adjusted staggering effect
            ease: "easeOut", // Smooth transition
            repeat: Infinity
        },
    }),
    transform: (i) => ({
        x: i === 0 ? '75%' : i === 1 ? '25%' : i === 2 ? '-25%' : i === 3 ? '-75%' : 0,
        transition: {
            duration: 1.25, // Consistent with the visibility transition for smoothness
            delay: 0.45, // Adjusted for overall timing
            ease: "easeOut", // Ensures smooth ending
        },
    }),
};
const screenVariantsMobile = {
    hidden: (i) => ({
        x: 0,
        y: '50%'
    }),
    visible: (i) => ({
        transition: {
            duration: 1.25, // Increased duration for smoother animation
            delay: i * 0.1, // Adjusted staggering effect
            ease: "easeOut", // Smooth transition
            repeat: Infinity
        },
    }),
    transform: (i) => ({
        x: i === 0 ? '25%' : i === 1 ? '-25%' : i === 2 ? '25%' : i === 3 ? '-25%' : '0%',
        y: i === 0 ? '100%' : i === 1 ? '100%' : i === 2 ? '50%' : i === 3 ? '50%' : 0,
        transition: {
            duration: 1.25, // Consistent with the visibility transition for smoothness
            delay: 0.45, // Adjusted for overall timing
            ease: "easeOut", // Ensures smooth ending
        },
    }),
};
function Channel({ language, setLanguage }) {
    const { width } = useWindowSize()
    const imgRef = useRef(null);
    const imgInView = useInView(imgRef, { once: true, amount: 0.5 });
    const imgControls = useAnimation();

    const { marketingPage } = useContext(TranslationContext);
    const { channel } = marketingPage;

    useEffect(() => {
        if (imgInView) imgControls.start(["visible", "transform"]);
    }, [imgInView, imgControls]);

    return (
        <div className='channel'>
            <p className='channel__title'>{channel.title}</p>
            <p className='channel__subtitle'>
                {channel.subtitle}
            </p>
            <motion.div
                className='channel__img-box'
                ref={imgRef}
                initial="hidden"
                animate={imgControls}
            >
                {images.map((img, i) => (
                    <motion.img
                        className={`channel__img channel__img-${i}`}
                        key={`mobile-first-screen-${i}`}
                        custom={i}
                        variants={width > 900 ? screenVariants : screenVariantsMobile}
                        src={img} alt=""
                    />
                ))}
            </motion.div>
        </div>
    );
}

export default Channel