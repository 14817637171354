import { Route, Routes, useSearchParams } from "react-router-dom";
import "./App.css";
import Main from "../Main/Main";
import Price from "../Price/Price";
import Partners from "../Partners/Partners";
import AboutUs from "../AboutUs/AboutUs";
import Blog from "../Blog/Blog";
import { useEffect, useState } from "react";
import {
  FOOTER_LINKS_COLUMN,
  LANGUAGES,
  VALIDE_LANG,
  initialJoinValues,
  initialSignupValues,
  initialSugnupPartners,
  initialSugnupValidity,
  productIdStorageKey
} from "../../assets/utils/constants";
import Header from "../Header/Header";
import { useTheme } from "../../assets/hooks/use-theme";
import { ThemeContext } from "../../assets/contexts/themeContext";
import Footer from "../Main/Footer/Footer";
import validator from "validator";
import SignUp from "../Auth/SignUp/SignUp";
import Article from "../Blog/Article/Article";
import { TranslationContext, translations } from "../../assets/contexts/translationContext";
import { EN_LANGUAGE } from "../../assets/utils/constants";
import Marketing from "../Main/Marketing/Marketing";
import MarketingPage from "../MarketingPage/MarketingPage";

function App() {
  const { theme, setTheme } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  if (!localStorage.getItem('referrer')) {
    const referrer = document.referrer;
    if (referrer) localStorage.setItem('referrer', referrer)
  }
  const [isUTMHandled, setIsUTMHandled] = useState(false); // Flag to control one-time execution

  const handleUTMParams = () => {
    const utmTags = {};
    let hasUTM = false;
    const date = new Date().toISOString();

    // Consolidate UTM parameters into the utmTags object
    searchParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmTags[key] = value;
        hasUTM = true;
      }
    });

    if (hasUTM) {
      utmTags.date = date; // Add date only if UTM tags are found

      // Retrieve or initialize utm_history array in localStorage
      const utmHistory = JSON.parse(localStorage.getItem('utm_history')) || [];

      // Check if an identical entry exists in utm_history
      const isDuplicate = utmHistory.some(
        (entry) => {
          // Check if the current entry matches all UTM parameters in utmTags
          const { date: entryDate, ...entryWithoutDate } = entry;
          const { date: utmDate, ...utmTagsWithoutDate } = utmTags;
          return JSON.stringify(entryWithoutDate) === JSON.stringify(utmTagsWithoutDate);
        }
      );

      if (!isDuplicate) {
        utmHistory.unshift(utmTags); // Add new UTM tags as the latest entry
        localStorage.setItem('utm_history', JSON.stringify(utmHistory)); // Save updated history
      }

      // Remove UTM parameters from URL after saving
      searchParams.forEach((_, key) => {
        if (key.startsWith('utm_')) {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
    }
  };

  // Execute UTM handling only if not already handled
  if (!isUTMHandled) {
    handleUTMParams();
    setIsUTMHandled(true); // Set the flag to true to prevent re-running
  }

  const value = searchParams.get("value");
  const lang = searchParams.get("lang");
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState(undefined);
  const [searchKey, setSearchKey] = useState("");
  const [isSignupPopupOpen, setIsSignupPopupOpen] = useState(false);
  const [signupValues, setSignupValues] = useState(initialSignupValues);
  const [joinValues, setJoinValues] = useState(initialJoinValues);
  const [signupValidity, setSignupValidity] = useState(initialSugnupValidity);

  useEffect(() => {
    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];
    localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
  }, [])

  const [language, setLanguage] = useState(
    localStorage.getItem("language") !== null && VALIDE_LANG.includes(localStorage.getItem('language'))
      ? localStorage.getItem("language")
      : navigator.language && VALIDE_LANG.includes((new Intl.Locale(navigator.language)).language.toUpperCase()) ? (new Intl.Locale(navigator.language)).language.toUpperCase() : EN_LANGUAGE
  );

  useEffect(() => {
    if (lang && VALIDE_LANG.includes(lang)) {
      setLanguage(lang);
      localStorage.setItem("language", lang);
    }
    else {
      console.log(language)
      localStorage.setItem("language", language);
      setSearchParams(params => {
        params.set("lang", language);
        return params;
      });
    }
  }, [lang])

  // useEffect(() => {
  //     setSearchParams(params => {
  //       params.set("lang", language);
  //       return params;
  //     });
  // }, [language])


  useEffect(() => {
    if (!value) return;

    const products = [
      ...FOOTER_LINKS_COLUMN,
      // ...CARDS_PROMO,
      // ...CARDS_POPULAR,
      // ...CARDS_SUB,
      // EXAMPLE_CARD.data,
    ];
    console.log(products);
    const filtered_products = products.filter(
      (item) =>
        item.title.toLowerCase().includes(value.toLowerCase()) ||
        item.title.toLowerCase().includes(searchKey.toLowerCase())
    );
    setItems(filtered_products);
  }, [value, searchKey]);

  function handleSignupPopupOpen() {
    setIsSignupPopupOpen(true);
  }

  function handleSignupPopupClose() {
    setIsSignupPopupOpen(false);
    setSignupValues(initialSignupValues);
    setSignupValidity(initialSugnupValidity);
  }

  function handleJoinPopupClose() {
    // setIsSignupPopupOpen(false);
    setJoinValues(initialJoinValues);
    setSignupValidity(initialSugnupValidity);
  }

  function handleSignupValuesChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "email": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        if (!value) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: true,
              },
            }));
          } else {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !evt.target.validity.valid
                  ? evt.target.validationMessage
                  : "Invalid email",
                validState: false,
              },
            }));
          }
        }
        break;
      }

      case "code": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        break;
      }


      case "password": {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: value.length >= 8,
          },
        }));
        break;
      }

      case "phone": {
        let inputValue = value.replace(/(?!^\+)\D/g, "");

        setSignupValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));

        // if (inputValue.match(/^(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/g)) {4
        if (inputValue.length >= 8) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          }));
        } else {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        break;
      }

      default: {
        setSignupValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
      }
    }
  }
  function handleJoinValuesChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "email": {
        setJoinValues((prevVal) => ({ ...prevVal, [name]: value }));
        if (!value) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: true,
              },
            }));
          } else {
            setSignupValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !evt.target.validity.valid
                  ? evt.target.validationMessage
                  : "Invalid email",
                validState: false,
              },
            }));
          }
        }
        break;
      }

      case "phone": {
        let inputValue = value.replace(/\D/g, "");
        setJoinValues((prevValue) => ({
          ...prevValue,
          [name]: inputValue,
        }));

        // if (inputValue.match(/^(?:50|51|52|55|56|58|2|3|4|6|7|9)\d{7}$/g)) {4
        if (inputValue.length >= 8) {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: true,
            },
          }));
        } else {
          setSignupValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "",
              validState: false,
            },
          }));
        }
        break;
      }

      default: {
        setJoinValues((prevVal) => ({ ...prevVal, [name]: value }));
        setSignupValidity((prevVal) => ({
          ...prevVal,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
      }
    }
  }
  return (
    <TranslationContext.Provider value={translations[language]}>
      <div className="app">
        <ThemeContext.Provider value={{ setTheme, theme }}>
          <Header  {...{
            language,
            setLanguage,
          }} />
          <Routes>
            <Route
              path="/"
              element={
                <Main
                  language={language}
                  theme={theme}
                  setLanguage={setLanguage}
                  onSignupOpen={handleSignupPopupOpen}
                  email={signupValues.email}
                  emailValidity={signupValidity.email}
                  onEmailChange={handleSignupValuesChange}
                />
              }
            />
            <Route
              path="/marketing"
              element={
                <MarketingPage
                  language={language}
                  setLanguage={setLanguage}
                  theme={theme}
                  onSignupOpen={handleSignupPopupOpen}
                />
              }
            />
            <Route
              path="/prices"
              element={
                <Price
                  language={language}
                  setLanguage={setLanguage}
                  theme={theme}
                  onSignupOpen={handleSignupPopupOpen}
                />
              }
            />
            <Route path="/about" element={
              <AboutUs
                cleanForm={handleJoinPopupClose}
                onChange={handleJoinValuesChange}
                values={joinValues}
                valuesValidity={signupValidity}
                language={language}
                setLanguage={setLanguage} />
            } />
            <Route
              path="/blog"
              element={
                <Blog
                  onSignupOpen={handleSignupPopupOpen}
                  email={signupValues.email}
                  emailValidity={signupValidity.email}
                  onEmailChange={handleSignupValuesChange}
                  searchValue={value}
                  {...{ searchKey, setSearchKey, theme, language, setLanguage, setSignupValidity, setSignupValues }}
                />
              }
            />
            <Route
              path="/blog/post/:translit_name"
              element={<Article
                language={language}
                setLanguage={setLanguage}
                onSignupOpen={handleSignupPopupOpen}

                email={signupValues.email}
                emailValidity={signupValidity.email}
                onEmailChange={handleSignupValuesChange}
                {...{ searchKey, setSearchKey, theme, language, setLanguage, setSignupValidity, setSignupValues }}
              />}
            />
            <Route path="/partners" element={
              <Partners
                cleanForm={handleSignupPopupClose}
                onChange={handleSignupValuesChange}
                values={signupValues}
                valuesValidity={signupValidity}
                language={language}
                setLanguage={setLanguage} />
            } />
          </Routes>
          <Footer language={language} onSignupOpen={handleSignupPopupOpen} />
          <SignUp
            language={language}
            isOpen={isSignupPopupOpen}
            onClose={handleSignupPopupClose}
            values={signupValues}
            valuesValidity={signupValidity}
            onChange={handleSignupValuesChange}
          />
        </ThemeContext.Provider>
      </div>
    </TranslationContext.Provider>
  );
}

export default App;
