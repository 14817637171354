import { useContext, useEffect, useState } from 'react';
import './Advertising.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function Advertising({ language, setLanguage }) {
    const { marketingPage } = useContext(TranslationContext);
    const { advert } = marketingPage;
    const { list } = advert;
    const ADVERT = [
        { label: list.googleseo, percentage: 100, color: "linear-gradient(270deg, #FF0018 0%, #F9737F 96.35%)", startingValue: 35 },
        { label: list.meta, percentage: 100, color: "linear-gradient(270deg, #35C650 0%, #7EF973 96.35%)", startingValue: 30 },
        { label: list.social, percentage: 100, color: "linear-gradient(270deg, #FF00F5 0%, #F973F3 96.35%)", startingValue: 25 },
        { label: list.click, percentage: 100, color: "linear-gradient(270deg, #0057FF 0%, #7398F9 96.35%)", startingValue: 20 },
        { label: list.facebook, percentage: 100, color: "linear-gradient(270deg, #FF8A00 0%, #F9B373 96.35%)", startingValue: 15 },
    ];

    const [startAnimation, setStartAnimation] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true, // Анимация срабатывает только один раз при входе в зону видимости
        threshold: 0.3 // Процент видимой части элемента, при котором срабатывает триггер
    });

    useEffect(() => {
        if (inView) {
            setStartAnimation(true);
        }
    }, [inView]);

    const ProgressBars = ({ bars }) => {
        const [fillWidths, setFillWidths] = useState(bars.map(bar => bar.startingValue || 0));

        useEffect(() => {
            if (startAnimation) {
                bars.forEach((bar, index) => {
                    setTimeout(() => {
                        setFillWidths(prevWidths => {
                            const newWidths = [...prevWidths];
                            newWidths[index] = bar.percentage;
                            return newWidths;
                        });
                    }, index * 200); // Уменьшенная задержка 200мс между каждым прогресс-баром
                });
            }
        }, [startAnimation, bars]);


        const AnimatedNumber = ({ start, end, duration }) => {
            const [number, setNumber] = useState(start);

            useEffect(() => {
                if (!startAnimation) return;

                const startTime = performance.now();

                const animate = (currentTime) => {
                    const elapsedTime = currentTime - startTime;
                    const progress = Math.min(elapsedTime / duration, 1); // Ограничиваем до 1 (100%)
                    const currentNumber = Math.floor(start + (end - start) * progress); // Вычисляем текущее число

                    setNumber(currentNumber);

                    if (progress < 1) {
                        requestAnimationFrame(animate);
                    }
                };

                requestAnimationFrame(animate);
            }, [start, end, duration, startAnimation]);

            return (
                <div className='animate-number'>
                    {number}%
                </div>
            );
        };

        return (
            <div className="progress-bar-container">
                {bars.map((bar, index) => (
                    <div key={index} className="progress-bar-wrapper">
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: (index + 0.5) * 0.5 }}
                            className="progress-bar-label">{bar.label}</motion.p>
                        <div className="progress-bar">
                            <div
                                className="progress-bar-fill"
                                style={{
                                    width: `${fillWidths[index]}%`,
                                    background: bar.color,
                                    transition: `width 1.5s ease-in-out ${index * 0.2}s`,
                                }}
                            >
                                <AnimatedNumber start={bar.startingValue} end={100} duration={2000} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div ref={ref} className='advertising'>
            <p className='advertising__title'>{advert.title}</p>
            <p className='advertising__subtitle'>
                {advert.subtitle}
            </p>
            <div className='advertising__procent__box'>
                <ProgressBars bars={ADVERT} />
            </div>
        </div>
    );
}

export default Advertising;
