import React, { useContext, useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';

import video_white from '../../../assets/videos/logistics/white.mp4';
import video_black from '../../../assets/videos/logistics/black.mp4';
import './Logistics.css';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { ThemeContext } from '../../../assets/contexts/themeContext';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/logistics', false, /item\d+\.webp$/));

const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/itemBlack(\d+)/)[1]);
            const numB = parseInt(b.match(/itemBlack(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const imagesBlack = importAllBlack(require.context('../../../assets/images/logistics/black', false, /itemBlack\d+\.webp$/));

function Logistics() {
    const { main } = useContext(TranslationContext);
    const { mainLogistic } = main;

    const videoRef = useRef(null);
    const isInView = useInView(videoRef, { once: true, margin: '-100px' });
    const { theme } = useContext(ThemeContext);

    // Unlock video for autoplay on iOS
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const unlockVideoAutoplay = () => {
            video.play().then(() => video.pause()).catch((error) => {
                console.error("Error trying to unlock video autoplay:", error);
            });

            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };

        document.addEventListener('touchstart', unlockVideoAutoplay);
        document.addEventListener('click', unlockVideoAutoplay);

        return () => {
            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };
    }, []);

    // Play the video when it comes into view
    useEffect(() => {
        const video = videoRef.current;
        if (video && isInView) {
            video.play().catch((error) => {
                console.error("Error trying to play the video:", error);
            });
        }
    }, [isInView]);

    const handleVideoError = (e) => {
        console.error("Video error event:", e);
        console.error("Native event details:", e.nativeEvent);
    };

    return (
        <div className='logistics' id='benefits' data-section>
            <h3 className='logistics__title'>{mainLogistic.titleFirstLine} <br />{mainLogistic.titleSecondLine}</h3>
            <p className='logistics__subtitle'>{mainLogistic.subtitle}</p>
            <div className='logistics__logos '>
                {theme === THEME_LIGHT_TYPE ?
                    images.map((item, i) => {
                        return (
                            <img style={{ gridArea: `item${i + 1}` }} src={item} alt='' className={`logistics__card logistics__card-${i}`}></img>
                        )
                    })
                    :
                    imagesBlack.map((item, i) => {
                        return (
                            <img style={{ gridArea: `item${i + 1}` }} src={item} alt='' className={`logistics__card logistics__card-${i}`}></img>
                        )
                    })
                }
            </div>

            {theme === THEME_LIGHT_TYPE ?
                <video
                    key={'logistics__video_video_white'}
                    className='logistics__video'
                    ref={videoRef}
                    src={video_white} // Source set directly
                    loop
                    autoPlay
                    muted
                    playsInline
                    preload="metadata"
                    onError={handleVideoError}
                >
                    {mainLogistic.errorVideo}
                </video>
                :
                <video
                    key={'logistics__video_video_black'} // Unique key changes with theme
                    className='logistics__video logistics__video_video_black'
                    ref={videoRef}
                    src={video_black} // Source set directly
                    loop
                    autoPlay
                    muted
                    playsInline
                    preload="metadata"
                    onError={handleVideoError}
                >
                    {mainLogistic.errorVideo}
                </video>
            }
        </div>
    );
}

export default Logistics;
