import './Business.css';
import main from '../../../assets/images/marketing-page/main.png'
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function Business({ onSignupOpen }) {
    const { marketingPage } = useContext(TranslationContext);
    const { business } = marketingPage;
    const { list } = business;

    const SUBT = [
        {
            text: list.onlinestore
        },
        {
            text: list.marketsolution
        },
        {
            text: list.selling
        },
    ]
    return (
        <div className='business'>
            <div className='business__text'>
                <p className='business__title'>{business.title}</p>
                <div className='business__subtitle'>
                    {SUBT.map((item, i) => {
                        return (
                            <div className='business__subtitle__box'>
                                <p>{i + 1}.</p>
                                <p>{item.text}</p>
                            </div>
                        )
                    })}
                </div>
                <button
                    onClick={onSignupOpen}
                    className='business__btn'>
                    {business.btn}
                </button>
            </div>
            <img src={main} alt='' className='business__img'></img>
        </div>
    );
}

export default Business