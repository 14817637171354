import { createContext } from "react";
import { EN_TRANSLATION } from "../translation/EN";

export const TranslationContext = createContext();

export const translations = {
  EN: EN_TRANSLATION,
  LX: EN_TRANSLATION,
  BG: EN_TRANSLATION,
  RO: EN_TRANSLATION,
  UAE: EN_TRANSLATION,
  DM: EN_TRANSLATION,
  FL: EN_TRANSLATION,
  CZ: EN_TRANSLATION,
  GC: EN_TRANSLATION,
  AS: EN_TRANSLATION,
  SP: EN_TRANSLATION,
  LH: EN_TRANSLATION,
  SL: EN_TRANSLATION,
  FR: EN_TRANSLATION,
  IR: EN_TRANSLATION,
  HG: EN_TRANSLATION,
  BL: EN_TRANSLATION,
  CR: EN_TRANSLATION,
  ET: EN_TRANSLATION,
  LT: EN_TRANSLATION,
  IT: EN_TRANSLATION,
  PL: EN_TRANSLATION,
  MT: EN_TRANSLATION,
  NL: EN_TRANSLATION,
  GM: EN_TRANSLATION,
  SW: EN_TRANSLATION,
  SK: EN_TRANSLATION
};