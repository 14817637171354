import React, { useContext, useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';

import video_white from '../../../assets/videos/marketing/grey.mp4';
import video_black from '../../../assets/videos/marketing/grey-black.mp4';
import lk from '../../../assets/images/marketing/lk.webp';

import './Marketing.css';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { ThemeContext } from '../../../assets/contexts/themeContext';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';

function Marketing() {
    const { main } = useContext(TranslationContext);
    const { mainMarketing } = main;

    const videoRef = useRef(null);
    const isInView = useInView(videoRef, { once: true, margin: '-100px' });
    const { theme } = useContext(ThemeContext);

    const { width } = useWindowSize()
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        let isUnlocked = false; // Flag to indicate if video has been unlocked

        const unlockVideoAutoplay = () => {
            if (isUnlocked) return; // If already unlocked, do nothing
            isUnlocked = true; // Set the flag to true

            video.play().then(() => {
                video.pause();
            }).catch((error) => {
                console.error("Error trying to unlock video autoplay:", error);
            });

            // Clean up event listeners
            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };

        // Add event listeners to unlock video autoplay on the first user interaction
        document.addEventListener('touchstart', unlockVideoAutoplay);
        document.addEventListener('click', unlockVideoAutoplay);

        return () => {
            document.removeEventListener('touchstart', unlockVideoAutoplay);
            document.removeEventListener('click', unlockVideoAutoplay);
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video || !isInView) return;

        // Autoplay when in view
        video.play().catch((error) => {
            console.error("Error trying to play the video:", error);
        });
    }, [isInView]);

    const handleVideoError = (e) => {
        console.error("Video error event:", e);
        console.error("Native event details:", e.nativeEvent);
    };

    return (
        <div className='marketing'>
            <div className='marketing__content'>
                {width > 900 ?
                    <h3 className='marketing__title'>
                        <svg className='marketing__title__icon' xmlns="http://www.w3.org/2000/svg" width="91" height="91" viewBox="0 0 91 91" fill="none">
                            <path d="M45.5 79.625C64.3467 79.625 79.625 64.3467 79.625 45.5C79.625 26.6533 64.3467 11.375 45.5 11.375C26.6533 11.375 11.375 26.6533 11.375 45.5C11.375 64.3467 26.6533 79.625 45.5 79.625Z" fill="#2BBC5D" stroke="#2BBC5D" stroke-width="1.4734" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M47.6698 52.6053H53.5997V26H47.6698V52.6053ZM37.4466 52.6053H43.3766V26H37.4466V52.6053ZM29.25 56.0574L31.5651 54.9067C32.4815 54.4638 33.1071 54.1989 33.5895 54.1989C34.3605 54.1989 34.7945 54.7307 35.4222 55.4386C37.0611 57.2526 40.004 59.9988 45.5 59.9988C50.996 59.9988 53.9389 57.2545 55.5778 55.4386C56.2055 54.7307 56.6395 54.1989 57.4105 54.1989C57.8929 54.1989 58.5185 54.4638 59.4349 54.9067L61.75 56.0574C57.6991 62.8746 51.6238 65 45.5 65C39.3762 65 33.3009 62.8746 29.25 56.0574Z" fill="white" />
                        </svg>
                        {mainMarketing.titleFirstLine}
                    </h3>
                    :
                    <h3 className='marketing__title'>
                        {mainMarketing.titleFirstLineMobile}
                    </h3>
                }
                <p className='marketing__subtitle'>{mainMarketing.subtitle}</p>
                <div className='marketing__box'>
                    {theme === THEME_LIGHT_TYPE ?
                        <video
                            key={`marketing__video_video_white`}
                            className='marketing__video'
                            ref={videoRef}
                            src={video_white}
                            autoPlay
                            muted
                            playsInline
                            loop
                            preload="metadata"
                            onError={handleVideoError}
                        >
                            {mainMarketing.errorVideo}
                        </video>
                        :
                        <video
                            key={`marketing__video_video_black`}
                            className='marketing__video'
                            ref={videoRef}
                            src={video_black}
                            autoPlay
                            muted
                            playsInline
                            loop
                            preload="metadata"
                            onError={handleVideoError}
                        >
                            {mainMarketing.errorVideo}
                        </video>
                    }

                    <img className='marketing__img' src={lk} alt='' />
                </div>
            </div>
        </div>
    );
}

export default Marketing;
