import { useSearchParams } from 'react-router-dom';
import Reviews from '../Main/Reviews/Reviews';
import Bennefits from './Bennefits/Bennefits';
import ChoosePlan from './ChoosePlan/ChoosePlan';
import CompareFeatures from './CompareFeatures/CompareFeatures';
import FAQ from './FAQ/FAQ';
import './Price.css';
import Suitable from './Suitable/Suitable';
import { useContext, useEffect } from 'react';
import { VALIDE_LANG } from '../../assets/utils/constants';
import { Helmet } from 'react-helmet';
import { TranslationContext } from '../../assets/contexts/translationContext';

function Price({ onSignupOpen, theme, language, setLanguage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  const { helmet } = useContext(TranslationContext);

  useEffect(() => {
    if (lang && VALIDE_LANG.includes(lang)) {
      setLanguage(lang);
      localStorage.setItem("language", lang);
    }
    else {
      setSearchParams(params => {
        params.set("lang", language);
        return params;
      });
      localStorage.setItem("language", language);
    }
  }, [])

  return (
    <div className='price'>
      <Helmet>
        <title>{helmet.prices.title}</title>
        <meta name="description" content={helmet.prices.desc} />
      </Helmet>
      <ChoosePlan {...{ onSignupOpen }} />
      <CompareFeatures {...{ onSignupOpen }} />
      <FAQ />
      <Reviews theme={theme} />
      <Bennefits {...{ onSignupOpen }} />
      <Suitable />
    </div>
  );
}

export default Price