import { motion, useAnimation, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import './Store.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { ThemeContext } from '../../../assets/contexts/themeContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item-(\d+)/)[1], 10); // Use [1] to get the correct match
            const numB = parseInt(b.match(/item-(\d+)/)[1], 10);
            return numA - numB;
        })
        .map((key) => requireContext(key)); // Ensure correct image import
const storeItems = importAll(require.context('../../../assets/images/store', false, /item-\d+\.webp$/));


const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item-(\d+)/)[1], 10); // Use [1] to get the correct match
            const numB = parseInt(b.match(/item-(\d+)/)[1], 10);
            return numA - numB;
        })
        .map((key) => requireContext(key)); // Ensure correct image import
const storeItemsBlack = importAllBlack(require.context('../../../assets/images/store/black', false, /item-\d+\.webp$/));

function Store({ onSignupOpen }) {
    const { main } = useContext(TranslationContext);
    const { mainFeatures } = main;
    const { iconText } = mainFeatures;
    const { theme } = useContext(ThemeContext)

    const titles = Object.values(iconText);
    const ITEMS = titles.map((title, index) => ({
        title,
        img: storeItems[index],
        imgBlack: storeItemsBlack[index]
    }));

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [controls, isInView, theme]);

    return (
        <motion.div className='store' ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
        >
            <motion.h3 className='store__title' variants={itemVariants}>
                {mainFeatures.titleFirstLine}<br></br>{mainFeatures.titleSecondLine}
            </motion.h3>
            <motion.p className='store__subtitle' variants={itemVariants}>
                {mainFeatures.text}
            </motion.p>
            <motion.div className='store__items'>
                {ITEMS.map((item, i) => (
                    <motion.div className='store__item' key={`store__item_${i}`} variants={itemVariants}>
                        {theme === THEME_LIGHT_TYPE ?
                            <img className='store__item-img' src={item.img} alt='' />
                            :
                            <img className='store__item-img' src={item.imgBlack} alt='' />
                        }
                        <p className='store__item-title'>{item.title}</p>
                    </motion.div>
                ))}
            </motion.div>
            <motion.button className='store__btn' variants={itemVariants} type='button' onClick={onSignupOpen}>
                {mainFeatures.textBtn}
            </motion.button>
        </motion.div>
    );
}

export default Store