import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import './Header.css';
import { LogoIcon, MobileMenuIcon, SunIcon } from '../../assets/icons/icons';
import { SELLAVI_API_URL, THEME_DARK_TYPE, THEME_LIGHT_TYPE } from '../../assets/utils/constants';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../assets/contexts/themeContext';
import useScrollDirection from '../../assets/hooks/useScrollDirection';
import HeaderPopup from './HeaderPopup/HeaderPopup';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { TranslationContext } from "../../assets/contexts/translationContext";
import { motion } from "framer-motion";
import {
    LANGUAGES,
    EN_LANGUAGE,
} from "../../assets/utils/constants";
import LanguagePopup from './LanguagePopup/LanguagePopup';

function Header({ language, setLanguage }) {
    const { theme, setTheme } = useContext(ThemeContext);
    const { header } = useContext(TranslationContext);
    const { headerLink } = header;
    const { aboutUs, prices, affiliateProgram, marketing, howToThriveInEcomm } = headerLink;

    const scrollDirection = useScrollDirection();
    const [isHeaderPopupOpen, setHeaderPopupOpen] = useState(false)
    const [isOpenDropDown, setDropdownOpen] = useState(false)
    const { pathname } = useLocation()
    const { width } = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    function selectLanguage(lang) {
        // localStorage.setItem("language", lang);
        // setLanguage(lang);
        setSearchParams(params => {
            params.set("lang", lang);
            return params;
        });
        setDropdownOpen(!isOpenDropDown)
        // if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
    }

    const HEADER_LINKS = [
        // {
        //     path: '/',
        //     name: 'Home'
        // },
        {
            path: '/about',
            name: `${aboutUs.name}`
        },
        {
            path: '/prices',
            name: `${prices.name}`
        },
        {
            path: '/partners',
            name: `${affiliateProgram.name}`
        },
        {
            path: '/marketing',
            name: `${marketing.name}`
        },
        // {
        //     path: '/blog',
        //     name: `${howToThriveInEcomm.name}`
        // },
    ]

    return (
        <header className={`header ${scrollDirection === "down" ? "header_hide" : "header_show"}`} style={{ '--logo-position': width > 1920 ? `${(width - 1920) / 2 + 80}px` : '80px' }}>
            <div className='header__content'>
                <Link className='header__logo-box' to={'/'}>
                    <LogoIcon
                        mainClassName={`header__logo ${pathname === '/' && width > 1200 ? 'header__logo_fixed' : ''}`}
                    />
                </Link>
                <div className='header__links'>
                    {HEADER_LINKS.map((link, i) => (
                        <NavLink
                            key={`header__link_${i}`}
                            to={link.path}
                            className={({ isActive }) =>
                                `header__link ${isActive ? "header__link_active" : ""
                                } ${link.disabled ? "header__link_disabled" : ""}`
                            }
                        >
                            {link.name}
                        </NavLink>
                    ))}
                </div>
                <div className='header__controls'>
                    <a className='header__login' href={`${SELLAVI_API_URL}/register`} target='_blank' rel='noreferrer'>{header.signIn}</a>

                    <button className='header__control-mobile-menu' type='button' onClick={() => {
                        setHeaderPopupOpen(prevValue => !prevValue)
                    }}>
                        <MobileMenuIcon
                            mainClassName={'header__control-mobile-menu-icon'}
                            strokeClassName={'header__control-mobile-menu-icon-stroke'}
                        />
                    </button>
                    <button onClick={() => setDropdownOpen(!isOpenDropDown)} className='header__control-language header__control-language_desktop'>
                        {/* <p className='header__control-language__text'>Select Country</p> */}
                        {LANGUAGES.map((item) => {
                            return (
                                <>
                                    {item.trans === language ? item.flag : null}
                                </>
                            )
                        })}
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 5.875L0.316407 2.15625C0.0703135 1.88281 0.0703135 1.47266 0.316407 1.22656L0.945314 0.597656C1.19141 0.351562 1.62891 0.351562 1.875 0.597656L4.5 3.25L7.15234 0.597656C7.39844 0.351562 7.80859 0.351562 8.08203 0.597656L8.68359 1.22656C8.95703 1.47266 8.95703 1.88281 8.68359 2.15625L4.96484 5.875C4.71875 6.12109 4.30859 6.12109 4.03516 5.875Z" fill="var(--neutral-active)" />
                        </svg>
                    </button>

                    <LanguagePopup isLanguagePopupOpen={isOpenDropDown} setLanguagePopupOpen={setDropdownOpen} language={language} setLanguage={selectLanguage}/>

                    <button className='header__control' onClick={() => {
                        setTheme(theme === THEME_LIGHT_TYPE ? THEME_DARK_TYPE : THEME_LIGHT_TYPE)
                    }}>
                        <SunIcon
                            mainClassName={'header__control-icon'}
                            fillClassName={'header__control-icon-fill'}
                        />
                    </button>
                </div>
            </div>
            <HeaderPopup
                language={language}
                setLanguage={setLanguage}
                isHeaderPopupOpen={isHeaderPopupOpen}
                setHeaderPopupOpen={setHeaderPopupOpen}
            />
        </header>
    );
}

export default Header