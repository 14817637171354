import { useAnimation, useInView } from 'framer-motion';
import './Integration.css';
import { useContext, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/marketing-page/Integration', false, /item\d+\.webp$/));

const importAllIcons = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const icons = importAllIcons(require.context('../../../assets/images/marketing-page/Integration/icons', false, /item\d+\.webp$/));

const screenVariants = {
    hidden: (i) => ({
        opacity: i === 0 ? 1 : 0,
        rotate: 0,
        x: i === 0 ? "35%" : i === 2 ? "-46%" : 0,
        y: i === 0 ? "-5%" : i === 2 ? "-6%" : 0,
    }),
    visible: (i) => ({
        opacity: 1,
        rotate: i === 0 ? "-7.169deg" : i === 1 ? "2.243deg" : "10.203deg",
        transition: {
            duration: 0.25, // Increased duration for smoother animation
            delay: i * 0.1, // Adjusted staggering effect
            ease: "easeOut", // Smooth transition
        },
    }),
    transform: {
        x: 0,
        y: 0,
        transition: {
            duration: 0.25, // Consistent with the visibility transition for smoothness
            delay: 0.45, // Adjusted for overall timing
            ease: "easeOut", // Ensures smooth ending
        },
    },
};

const itemsVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: i * 0.1 + 0.75, // Fine-tuned for a smoother reveal of each item
            type: "spring", // Adds a natural dynamic effect
            stiffness: 120, // Adjust spring stiffness for desired bounce effect
            damping: 10, // Controls the oscillation: lower for more bounce
        },
    }),
};

function Integration({ language, setLanguage }) {
    const imgRef = useRef(null);
    const imgInView = useInView(imgRef, { once: true, amount: 0.5 });
    const imgControls = useAnimation();

    const { marketingPage } = useContext(TranslationContext);
    const { integration } = marketingPage;

    useEffect(() => {
        if (imgInView) imgControls.start(["visible", "transform"]);
    }, [imgInView, imgControls]);

    return (
        <div className='integration'>
            <p className='integration__title'>{integration.title}</p>
            <p className='integration__subtitle'>
                {integration.subtitle}
            </p>
            <motion.div
                className="integration__img-box"
                ref={imgRef}
                initial="hidden"
                animate={imgControls}
            >
                <ul className="integration__screens-list">
                    {images.map((img, i) => (
                        <motion.li
                            className="integration__screen-item"
                            key={`integration-screen-${i}`}
                            custom={i}
                            variants={screenVariants}
                        >
                            <img className="integration__screen-img" src={img} alt="" />
                        </motion.li>
                    ))}
                </ul>
                <ul className="integration__icons-list">
                    {icons.map((icon, i) => (
                        <motion.li
                            className="integration__icon-item"
                            key={`integration-icon-${i}`}
                            custom={i}
                            variants={itemsVariants}
                        >
                            <img className="integration__icon" src={icon} alt="" />
                        </motion.li>
                    ))}
                </ul>
            </motion.div>
        </div>
    );
}

export default Integration