import { CyberSecurity, CyberSecurityMobile, Ecommerce, EcommerceMobile, Legal, LegalMobile, Logisticts, LogistictsMobile, Marketing, MarketingMobile, Partership, PartnershipMobile, Quality, QualityMobile, ResearchDev, ResearchDevMobile, Support, SupportMobile } from '../../../assets/icons/icons';
import './Departments.css';
import research from '../../../assets/images/aboutUs/research.webp'
import quality from '../../../assets/images/aboutUs/quality.webp'
import support from '../../../assets/images/aboutUs/support.webp'
import ecomm from '../../../assets/images/aboutUs/ecomm.webp'
import marketing from '../../../assets/images/aboutUs/marketing.webp'
import partner from '../../../assets/images/aboutUs/pertner.webp'
import legal from '../../../assets/images/aboutUs/legal.webp'
import cyber from '../../../assets/images/aboutUs/cyber.webp'
import logict from '../../../assets/images/aboutUs/lodictic.webp'
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { useContext, useState } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import PopupJoin from '../PopupJoin/PopupJoin';
function Departments({ valuesValidity, values, onChange, cleanForm }) {
    const { aboutUs } = useContext(TranslationContext);
    const { departments } = aboutUs;
    const { cards } = departments;
    const [isOpen, setOpen] = useState(false)

    const CARDS = [
        {
            title: `${cards.card1}`,
            img: research,
            icon: <ResearchDev />,
            iconMobile: <ResearchDevMobile />
        },
        {
            title: `${cards.card2}`,
            img: quality,
            icon: <Quality />,
            iconMobile: <QualityMobile />
        },
        {
            title: `${cards.card3}`,
            img: support,
            icon: <Support />,
            iconMobile: <SupportMobile />
        },
        {
            title: `${cards.card4}`,
            img: ecomm,
            icon: <Ecommerce />,
            iconMobile: <EcommerceMobile />
        },
        {
            title: `${cards.card5}`,
            img: marketing,
            icon: <Marketing />,
            iconMobile: <MarketingMobile />
        },
        {
            title: `${cards.card6}`,
            img: partner,
            icon: <Partership />,
            iconMobile: <PartnershipMobile />
        },
        {
            title: `${cards.card7}`,
            img: legal,
            icon: <Legal />,
            iconMobile: <LegalMobile />
        },
        {
            title: `${cards.card8}`,
            img: cyber,
            icon: <CyberSecurity />,
            iconMobile: <CyberSecurityMobile />
        },
        {
            title: `${cards.card9}`,
            img: logict,
            icon: <Logisticts />,
            iconMobile: <LogistictsMobile />
        },
    ]
    const window = useWindowSize()

    return (
        <div className='depart'>
            <PopupJoin
                isOpen={isOpen}
                setOpen={setOpen}
                valuesValidity={valuesValidity}
                values={values}
                onChange={onChange}
                cleanForm={cleanForm}
            />
            <p className='depart__title'>
                {departments.title}
            </p>
            <div className='depart__cards-box'>
                {CARDS.map((item, i) => {
                    return (
                        <div className='depart__card' key={item.title}>
                            <div className='depart__card_icon-and-text'>
                                <div className='depart__card__icon'>
                                    {window.width < 960 ? item.iconMobile : item.icon}</div>
                                <p className='depart__card__text'>{item.title}</p>
                            </div>
                            <img alt="" src={item.img} className='depart__card__img'></img>
                        </div>
                    )
                })}
            </div>
            <button onClick={() => setOpen(!isOpen)} className='depart__button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <path d="M13.5728 28.7844V0.784424H15.022V28.7844H13.5728ZM28.0649 15.2766H0.0649414V13.8274H28.0649V15.2766Z" fill="white" />
                </svg>
                {departments.joinBtn}
            </button>
        </div>
    );
}

export default Departments