import { useContext } from 'react';
import './Tools.css';
import { ThemeContext } from '../../../assets/contexts/themeContext';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/marketing-page/tools', false, /item\d+\.webp$/));

const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const imagesBlack = importAllBlack(require.context('../../../assets/images/marketing-page/tools/black', false, /item\d+\.webp$/));

function Tools({ }) {
    const { theme } = useContext(ThemeContext)
    const { marketingPage } = useContext(TranslationContext);
    const { tools } = marketingPage;

    return (
        <div className='tools'>
            <p className='tools__title'>{tools.title}</p>
            <div className='tools__logos'>
                {theme === THEME_LIGHT_TYPE ?
                    images.map((item, i) => {
                        return (
                            <div>
                                <img src={item} alt='' className='tools__logo'></img>
                            </div>
                        )
                    })
                    :
                    imagesBlack.map((item, i) => {
                        return (
                            <div>
                                <img src={item} alt='' className='tools__logo'></img>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Tools