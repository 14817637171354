import { Link, NavLink, useSearchParams } from "react-router-dom";
// import { CONTACT_EMAIL, CONTACT_PHONE } from "../../../assets/constants/constants";
import "./HeaderPopup.css";
import {
  SELLAVI_API_URL,
  THEME_DARK_TYPE,
  THEME_LIGHT_TYPE,
} from "../../../assets/utils/constants";
import { CloseIcon, LogoIcon, SunIcon } from "../../../assets/icons/icons";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../assets/contexts/themeContext";
// import { SellaviPointIcon } from "../../../assets/icons/price";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import {
  LANGUAGES,
  EN_LANGUAGE,
} from "../../../assets/utils/constants";
import { motion } from "framer-motion";
import LanguagePopup from "../LanguagePopup/LanguagePopup";

function HeaderPopup({ isHeaderPopupOpen, setHeaderPopupOpen, language, setLanguage }) {
  const { theme, setTheme } = useContext(ThemeContext);
  const { header } = useContext(TranslationContext);
  const { headerLink } = header;
  const { aboutUs, prices, affiliateProgram, howToThriveInEcomm, marketing } = headerLink;
  const [searchParams, setSearchParams] = useSearchParams();

  const onMenuBtnHandler = () => {
    setHeaderPopupOpen(!isHeaderPopupOpen);
  };
  function selectLanguage(lang) {
    // localStorage.setItem("language", lang);
    // setLanguage(lang);
    setSearchParams(params => {
      params.set("lang", lang);
      return params;
    });
    setDropdownOpen(!isOpenDropDown)
    // if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
  }
  const [isOpenDropDown, setDropdownOpen] = useState(false)

  const HEADER_LINKS = [
    // {
    //     path: '/',
    //     name: 'Home'
    // },
    {
      path: '/about',
      name: `${aboutUs.name}`
    },
    {
      path: '/prices',
      name: `${prices.name}`
    },
    {
      path: '/partners',
      name: `${affiliateProgram.name}`
    },
    {
      path: '/marketing',
      name: `${marketing.name}`
  },
    // {
    //   path: '/blog',
    //   name: `${howToThriveInEcomm.name}`
    // },
  ]

  return (
    <div
      className={`header-popup ${isHeaderPopupOpen ? "header-popup_opened" : ""
        }`}
    >
      <div className="header-popup__box">
        <div className="header-popup__heading">
          <Link
            className="header-popup__logo-link"
            to={"/"}
            onClick={onMenuBtnHandler}
          >
            <LogoIcon mainClassName={"header-popup__logo"} />
          </Link>
          <button
            className="header-popup__close"
            type="button"
            onClick={() => setHeaderPopupOpen(false)}
          >
            <CloseIcon
              mainClassName={"header-popup__close-icon"}
              strokeClassName={"header-popup__close-icon-stroke"}
              fillClassName={"header-popup__close-icon-fill"}
            />
          </button>
        </div>

        <div className="header-popup__content">
          <div className="header-popup__controls">
            <a
              className="header-popup__login"
              href={`${SELLAVI_API_URL}`} target='_blank' rel='noreferrer'
            >
              {header.signIn}
            </a>
            <button onClick={() => setDropdownOpen(!isOpenDropDown)} className='header__control-language header__control-language_mobile'>
              {/* <p className='header__control-language__text'>Select Country</p> */}
              {LANGUAGES.map((item) => {
                return (
                  <>
                    {item.trans === language ? item.flag : null}
                  </>
                )
              })}
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.03516 5.875L0.316407 2.15625C0.0703135 1.88281 0.0703135 1.47266 0.316407 1.22656L0.945314 0.597656C1.19141 0.351562 1.62891 0.351562 1.875 0.597656L4.5 3.25L7.15234 0.597656C7.39844 0.351562 7.80859 0.351562 8.08203 0.597656L8.68359 1.22656C8.95703 1.47266 8.95703 1.88281 8.68359 2.15625L4.96484 5.875C4.71875 6.12109 4.30859 6.12109 4.03516 5.875Z" fill="var(--neutral-active)" />
              </svg>
            </button>
            <LanguagePopup isLanguagePopupOpen={isOpenDropDown} setLanguagePopupOpen={setDropdownOpen} language={language} setLanguage={selectLanguage} />

            <button
              className="header-popup__control"
              type="button"
              onClick={() => {
                setTheme(
                  theme === THEME_LIGHT_TYPE
                    ? THEME_DARK_TYPE
                    : THEME_LIGHT_TYPE
                );
              }}
            >
              <SunIcon
                mainClassName={"header-popup__theme-icon"}
                fillClassName={"header-popup__theme-icon-fill"}
              />
            </button>
          </div>

          <div className="header-popup__box-nav-links">
            {HEADER_LINKS.map((link, i) => (
              <NavLink
                key={`header-popup__box-nav-links-link_${i}`}
                onClick={onMenuBtnHandler}
                to={link.path}
                className={({ isActive }) =>
                  `header-popup__box-nav-links-link ${isActive ? "header-popup__box-nav-links-link_active" : ""
                  }`
                }
              >
                <span className="header-popup__box-nav-links-link-txt">
                  {link.name}
                </span>
                {/* <SellaviPointIcon
                                mainClassName={'header-popup__box-nav-links-dot'}
                            /> */}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="header-popup__box-contacts">
          {/* <a className='header-popup__box-contacts-box'
                        href={`tel:${CONTACT_PHONE.formated_value}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M21.703 19.133L20.402 17.832C19.752 17.1821 18.6984 17.1821 18.0495 17.832L17.0269 18.8546C16.7984 19.0831 16.4524 19.1596 16.1574 19.0299C14.6756 18.381 13.2115 17.3972 11.9061 16.0918C10.6062 14.7919 9.62574 13.3345 8.97579 11.8583C8.84048 11.5533 8.91923 11.195 9.15547 10.9588L10.0716 10.0426C10.8158 9.29843 10.8158 8.24587 10.1659 7.59593L8.86488 6.29493C7.99865 5.42871 6.5945 5.42871 5.72828 6.29493L5.00513 7.01697C4.18327 7.83883 3.84055 9.02448 4.06237 10.2001C4.61028 13.0983 6.29393 16.2715 9.01018 18.9877C11.7264 21.7039 14.8996 23.3876 17.7978 23.9355C18.9734 24.1573 20.1591 23.8146 20.981 22.9927L21.703 22.2707C22.5692 21.4045 22.5692 20.0003 21.703 19.133Z" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.0886 8.46452C16.2321 8.449 17.3812 8.87268 18.2541 9.74556" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3907 6.60892C19.6505 4.86871 17.369 3.99805 15.0886 3.99805" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.5349 12.9108C19.5505 11.7673 19.1268 10.6182 18.2539 9.74536" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3875 6.60889C23.1277 8.3491 23.9983 10.6306 23.9983 12.9109" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className='header-popup__box-contacts-box-number'>
                            {CONTACT_PHONE.formated_value}
                        </span>
                    </a>
                    <a className='header-popup__box-contacts-box'
                        href={`mailto:${CONTACT_EMAIL}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M19.5573 6H8.44615C5.99155 6 4.00171 7.91025 4.00171 10.2667V17.7333C4.00171 20.0897 5.99155 22 8.44615 22H19.5573C22.0119 22 24.0017 20.0897 24.0017 17.7333V10.2667C24.0017 7.91025 22.0119 6 19.5573 6Z" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.44678 10.8145L13.1182 12.7506C13.3973 12.8663 13.6984 12.9262 14.0029 12.9267C14.3074 12.9271 14.6087 12.868 14.8882 12.7532L19.5569 10.8341" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className='header-popup__box-contacts-box-email'>
                            {CONTACT_EMAIL}
                        </span>
                    </a> */}
          {/*<a onClick={() => sendMetriс('reachGoal', 'CLICK_EMAIL')} className='header-popup__box-contacts-box' href={`mailto:${CONTACT_EMAIL}`}>
                     <PopupHeaderEmailIcon />
                     <span className='header-popup__box-contacts-box-email'>{CONTACT_EMAIL}</span>
                     </a>*/}
        </div>
      </div>
    </div>
  );
}

export default HeaderPopup;
