import './OurPartners.css';

// import master from '../../../assets/images/aboutUs/mastercard.svg'
// import maestro from '../../../assets/images/aboutUs/maestro.svg'
// import maestroBlack from '../../../assets/images/aboutUs/maestroBlack.svg'
// import tiktok from '../../../assets/images/aboutUs/tiktok.svg'
// import tiktokBlack from '../../../assets/images/aboutUs/tiktokBlack.svg'
// import insta from '../../../assets/images/aboutUs/insta.svg'
// import instaBlack from '../../../assets/images/aboutUs/instaBlack.svg'
// import tg from '../../../assets/images/aboutUs/tg.svg'
// import tgBlack from '../../../assets/images/aboutUs/tgBlack.svg'
// import visa from '../../../assets/images/aboutUs/visa.svg'
// import google from '../../../assets/images/aboutUs/google.svg'
// import fb from '../../../assets/images/aboutUs/fb.svg'
// import whap from '../../../assets/images/aboutUs/whap.svg'
// import whapBlack from '../../../assets/images/aboutUs/whapBlack.svg'
// import magnati from '../../../assets/images/aboutUs/magnati.svg'
// import masterBlack from '../../../assets/images/aboutUs/masterBlack.svg'
// import aramex from '../../../assets/images/aboutUs/aramex.svg'

import { ThemeContext } from "../../../assets/contexts/themeContext";
import { THEME_LIGHT_TYPE } from "../../../assets/utils/constants";
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';


const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/aboutUs/partners', false, /item\d+\.webp$/));

const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const imagesBlack = importAllBlack(require.context('../../../assets/images/aboutUs/partners/black', false, /item\d+\.webp$/));


function OurPartners() {
    const { theme } = useContext(ThemeContext);
    const { aboutUs } = useContext(TranslationContext);
    const { ourPartners } = aboutUs;

    return (
        <div className='partners'>
            <p className='partners__title'>{ourPartners.title}</p>

            <div className='partners__logo_line-1'>
                {theme === THEME_LIGHT_TYPE ?
                    images.map((item, i) => {
                        return (
                            <img
                                // style={{ gridArea: `card${i + 1}` }}
                                alt='' src={item}
                                className={`partners__logo_line-1__logo partners__logo_line-1__logo_card${i + 1}`}
                                key={`partners__logo_line-1__logo_card${i + 1}`}></img>
                        )
                    })
                    :
                    imagesBlack.map((item, i) => {
                        return (
                            <img
                                // style={{ gridArea: `card${i + 1}` }}
                                alt='' src={item}
                                className={`partners__logo_line-1__logo partners__logo_line-1__logo_card${i + 1}`}
                                key={`partners__logo_line-1__logo_card${i + 1}`}></img>
                        )
                    })
                }
            </div>

        </div>
    );
}

export default OurPartners