import { Helmet } from 'react-helmet';
import { TranslationContext } from '../../assets/contexts/translationContext';
import CreateWithAI from './CreateWithAI/CreateWithAI';
import HeadBanner from './HeadBanner/HeadBanner';
import Join from './Join/Join';
import Logistics from './Logistics/Logistics';
import './Main.css';
import MainNav from './MainNav/MainNav';
import Marketing from './Marketing/Marketing';
import MobileFirst from './MobileFirst/MobileFirst';
import Partners from './Partners/Partners';
import Reviews from './Reviews/Reviews';
import SayAboutUs from './SayAboutUs/SayAboutUs';
import Steps from './Steps/Steps';
import Store from './Store/Store';
import { useContext } from 'react';

function Main({ theme, onSignupOpen, onEmailChange, email, emailValidity, language }) {
    const { helmet } = useContext(TranslationContext);
    return (
        <div className='main'>
            <Helmet>
                <title>{helmet.main.title}</title>
                <meta name="description" content={helmet.main.desc} />
            </Helmet>
            <HeadBanner {...{ onSignupOpen, onEmailChange, email, emailValidity }} />
            <Reviews theme={theme} />
            <Steps />
            <MobileFirst />
            <Store {...{ onSignupOpen }} />
            <Partners />
            <CreateWithAI {...{ onSignupOpen, onEmailChange, email, emailValidity }} theme={theme} />
            {/* <Operates /> */}
            {/* <Magnati theme={theme} language={language} /> */}
            <Join />
            <Logistics />
            <SayAboutUs />
            <Marketing />
            <MainNav {...{ onSignupOpen }} />
        </div>
    );
}

export default Main