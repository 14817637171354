import { useSearchParams } from 'react-router-dom';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import './MarketingPage.css';
import { useContext, useEffect } from 'react';
import { VALIDE_LANG } from '../../assets/utils/constants';
import Business from './Business/Business';
import Tools from './Tools/Tools';
import Advertising from './Advertising/Advertising';
import Channel from './Channel/Channel';
import Analytics from './Analytics/Analytics';
import Direct from './Direct/Direct';
import Integration from './Integration/Itegration';
import { Helmet } from 'react-helmet';
import { TranslationContext } from '../../assets/contexts/translationContext';


function MarketingPage({ language, setLanguage, onSignupOpen }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");
    const { helmet } = useContext(TranslationContext);

    useEffect(() => {
        if (lang && VALIDE_LANG.includes(lang)) {
            setLanguage(lang);
            localStorage.setItem("language", lang);
        }
        else {
            setSearchParams(params => {
                params.set("lang", language);
                return params;
            });
            localStorage.setItem("language", language);
        }
    }, [])

    return (
        <div className='marketing-page'>
            <Helmet>
                <title>{helmet.marketing.title}</title>
                <meta name="description" content={helmet.marketing.desc} />
            </Helmet>
            <ScrollToTop />
            <div className='marketing-page__box'>
                <Business onSignupOpen={onSignupOpen} />
                <Tools />
                <Advertising />
                <Channel />
                <Analytics />
                <Direct />
                <Integration />
            </div>
        </div>
    );
}

export default MarketingPage