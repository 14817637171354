import './Direct.css';
import item from '../../../assets/images/marketing-page/direct/item.png';
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function Direct({ language, setLanguage }) {
    const { marketingPage } = useContext(TranslationContext);
    const { direct } = marketingPage;

    return (
        <div className='direct'>
            <p className='direct__title'>{direct.title}</p>
            <p className='direct__subtitle'>
                {direct.subtitle}
            </p>
            <img src={item} className='direct__img'></img>
        </div>
    );
}

export default Direct