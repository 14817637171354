import { useSearchParams } from "react-router-dom";
import "./Partners.css";
import PartnersForm from "./PartnersForm/PartnersForm";
import PartnersHeading from "./PartnersHeading/PartnersHeading";
import PartnersOverview from "./PartnersOverview/PartnersOverview";
import PartnersPros from "./PartnersPros/PartnersPros";
import PartnersTarget from "./PartnersTarget/PartnersTarget";
import { useContext, useEffect } from "react";
import { VALIDE_LANG } from "../../assets/utils/constants";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { Helmet } from "react-helmet";

function Partners({ language, setLanguage, valuesValidity, values, onChange, cleanForm }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");
  const { helmet } = useContext(TranslationContext);

  useEffect(() => {
    if (lang && VALIDE_LANG.includes(lang)) {
      setLanguage(lang);
      localStorage.setItem("language", lang);
    }
    else {
      setSearchParams(params => {
        params.set("lang", language);
        return params;
      });
      localStorage.setItem("language", language);
    }
  }, [])

  return (
    <section className="partners-page">
      <Helmet>
        <title>{helmet.partners.title}</title>
        <meta name="description" content={helmet.partners.desc} />
      </Helmet>
      <PartnersHeading />
      <PartnersPros />
      <PartnersOverview />
      <PartnersTarget />
      <PartnersForm valuesValidity={valuesValidity} values={values} onChange={onChange} cleanForm={cleanForm} />
    </section>
  );
}

export default Partners;
