import { Link, NavLink, useSearchParams } from "react-router-dom";
import "./LanguagePopup.css";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../assets/contexts/themeContext";
// import { SellaviPointIcon } from "../../../assets/icons/price";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { CUSTOM_LOCALES, LANGUAGES } from "../../../assets/utils/constants";

function LanguagePopup({ isLanguagePopupOpen, setLanguagePopupOpen, language }) {
    const { theme, setTheme } = useContext(ThemeContext);
    const { header } = useContext(TranslationContext);
    const { langPopup } = header
    const [searchParams, setSearchParams] = useSearchParams();
    function selectLanguage(lang) {
        // localStorage.setItem("language", lang);
        // setLanguage(lang);
        setSearchParams(params => {
            params.set("lang", lang);
            return params;
        });
        setLanguagePopupOpen(!isLanguagePopupOpen)
        // if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
    }

    useEffect(() => {
        if (isLanguagePopupOpen) {
            // Disable scrolling when the popup is open
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling again when the popup is closed
            document.body.style.overflow = 'auto'; // or ''
        }

        // Cleanup function to reset overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto'; // Reset to default
        };
    }, [isLanguagePopupOpen]);

    return (
        <div
            className={`language-popup ${isLanguagePopupOpen ? "language-popup_opened" : ""
                }`}
        >
            <div className="language-popup__box">
                <div className="language-popup__close" onClick={() => setLanguagePopupOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M2 2L18 18" stroke='#000' stroke-width="2.46154" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18 2L2 18" stroke='#000' stroke-width="2.46154" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <p className="language-popup__box__title">{langPopup.title}</p>
                <div className="language-popup__box__languages">
                    {LANGUAGES.map((item, i) => {
                        const isActive = String(localStorage.getItem("language")) === item.trans;
                        const className = `language-popup__box__language ${isActive ? 'language-popup__box__language_active' : ''}`;
                        return (
                            (CUSTOM_LOCALES.indexOf(item.trans) >= 0) ? (
                                <a
                                    key={i}
                                    href={`https://www.sellavi.com/${item.trans.toLowerCase()}${item.trans != 'UAE' ? `?lang=${item.trans}` : ''}`}
                                    className={className}
                                >
                                    {item.flag}
                                    {item.lang}
                                </a>
                            ) : (
                                <div
                                    key={i}
                                    onClick={() => selectLanguage(item.trans)}
                                    className={className}
                                >
                                    {item.flag}
                                    {item.lang}
                                </div>
                            )
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default LanguagePopup;