import { useContext } from 'react';
import './Analytics.css';
import { ThemeContext } from '../../../assets/contexts/themeContext';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/marketing-page/analytics', false, /item\d+\.webp$/));

const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const imagesBlack = importAllBlack(require.context('../../../assets/images/marketing-page/analytics/black', false, /item\d+\.webp$/));

function Analytics({ }) {
    const { theme } = useContext(ThemeContext)
    const { marketingPage } = useContext(TranslationContext);
    const { analytics } = marketingPage;

    return (
        <div className='analytics'>
            <p className='analytics__title'>{analytics.title}</p>
            <p className='analytics__subtitle'>
                {analytics.subtitle}
            </p>
            <div className='analytics__images'>
                {theme === THEME_LIGHT_TYPE ?
                    images.map((item, i) => {
                        return (
                            <div>
                                <img src={item} alt='' className='analytics__img'></img>
                            </div>
                        )
                    })
                    :
                    imagesBlack.map((item, i) => {
                        return (
                            <div className='analytics__img-box'>
                                <img src={item} alt='' className='analytics__img'></img>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
}

export default Analytics