import { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper/modules";
import ReviewSlide from "./ReviewSlide/ReviewSlide";
// import slide_1 from '../../../assets/images/reviews/bogoleva.webp'
// import slide_1_mobile from '../../../assets/images/reviews/bogoleva-mobile.webp'

import author_logo_1_black from '../../../assets/images/reviews/author-logo-1-black.webp'
import author_logo_1_white from '../../../assets/images/reviews/author-logo-1-white.webp'
import author_logo_2_black from '../../../assets/images/reviews/author-logo-2-black.webp'
import author_logo_2_white from '../../../assets/images/reviews/author-logo-2-white.webp'
import author_logo_3_black from '../../../assets/images/reviews/author-logo-3-black.webp'
import author_logo_3_white from '../../../assets/images/reviews/author-logo-3-white.webp'
import author_logo_4_black from '../../../assets/images/reviews/author-logo-4-black.webp'
import author_logo_4_white from '../../../assets/images/reviews/author-logo-4-white.webp'
import author_logo_5_black from '../../../assets/images/reviews/author-logo-5-black.webp'
import author_logo_5_white from '../../../assets/images/reviews/author-logo-5-white.webp'
import author_logo_6_black from '../../../assets/images/reviews/author-logo-6-black.webp'
import author_logo_6_white from '../../../assets/images/reviews/author-logo-6-white.webp'
import author_logo_7_black from '../../../assets/images/reviews/author-logo-7-black.webp'
import author_logo_7_white from '../../../assets/images/reviews/author-logo-7-white.webp'
import author_logo_8_black from '../../../assets/images/reviews/author-logo-8-black.webp'
import author_logo_8_white from '../../../assets/images/reviews/author-logo-8-white.webp'
import author_logo_9_black from '../../../assets/images/reviews/author-logo-9-black.webp'
import author_logo_9_white from '../../../assets/images/reviews/author-logo-9-white.webp'
import author_logo_10_black from '../../../assets/images/reviews/author-logo-10-black.webp'
import author_logo_10_white from '../../../assets/images/reviews/author-logo-10-white.webp'

import "swiper/css";
import "swiper/css/pagination";
import './Reviews.css';
import { TranslationContext } from "../../../assets/contexts/translationContext";

//import author img
const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/author-(\d+)/)[1]);
            const numB = parseInt(b.match(/author-(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const authors = importAll(require.context('../../../assets/images/reviews/authors', false, /author-\d+\.webp$/));
//import pc image
const importAllCards = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/pc(\d+)/)[1]);
            const numB = parseInt(b.match(/pc(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const cardImages = importAllCards(require.context('../../../assets/images/reviews/cards', false, /pc\d+\.webp$/));
//import phone img
const mobileCardImages = require
    .context('../../../assets/images/reviews/cards', false, /phone\d+\.webp$/)
    .keys()
    .sort((a, b) => {
        // Sort based on the numeric order in filename, assuming format 'phone<number>.webp'
        const numA = parseInt(a.match(/phone(\d+)\.webp$/)[1], 10);
        const numB = parseInt(b.match(/phone(\d+)\.webp$/)[1], 10);
        return numA - numB;
    })
    .map((file) => require(`../../../assets/images/reviews/cards/${file.replace('./', '')}`));

function Reviews({ theme }) {
    const { main } = useContext(TranslationContext);
    const { mainReviews } = main;
    const { revFirst, revSecond, revThird, revFour, revFive, revSix, revSeven, revEight, revNine, revTen } = mainReviews;

    const REVIEWS = [
        {
            author: {
                name: `${revFirst.name}`,
                site: 'maison.bg',
                img: authors[0],
                logo: author_logo_1_black,
                logo_theme_white: author_logo_1_white,
            },
            description: `${revFirst.description}`,
            img_pc: cardImages[0],
            img_mobile: mobileCardImages[0],
        },
        {
            author: {
                name: `${revSecond.name}`,
                site: 'roue.bg',
                img: authors[1],
                logo: author_logo_2_black,
                logo_theme_white: author_logo_2_white
            },
            description: `${revSecond.description}`,
            img_pc: cardImages[1],
            img_mobile: mobileCardImages[1],
        },
        {
            author: {
                name: `${revThird.name}`,
                site: 'houseoflememe.com',
                img: authors[2],
                logo: author_logo_3_black,
                logo_theme_white: author_logo_3_white
            },
            description: `${revThird.description}`,
            img_pc: cardImages[2],
            img_mobile: mobileCardImages[2],
        },
        {
            author: {
                name: `${revFour.name}`,
                site: 'trendyconfession.com',
                img: authors[3],
                logo: author_logo_4_black,
                logo_theme_white: author_logo_4_white
            },
            description: `${revFour.description}`,
            img_pc: cardImages[3],
            img_mobile: mobileCardImages[3],
        },
        {
            author: {
                name: `${revFive.name}`,
                site: 'invoke.bg',
                img: authors[4],
                logo: author_logo_5_black,
                logo_theme_white: author_logo_5_white
            },
            description: `${revFive.description}`,
            img_pc: cardImages[4],
            img_mobile: mobileCardImages[4],
        },
        {
            author: {
                name: `${revSix.name}`,
                site: 'bolgeva.com',
                img: authors[5],
                logo: author_logo_6_black,
                logo_theme_white: author_logo_6_white
            },
            description: `${revSix.description}`,
            img_pc: cardImages[5],
            img_mobile: mobileCardImages[5],
        },
        {
            author: {
                name: `${revSeven.name}`,
                site: 'altinbas.bg',
                img: authors[6],
                logo: author_logo_7_black,
                logo_theme_white: author_logo_7_white
            },
            description: `${revSeven.description}`,
            img_pc: cardImages[6],
            img_mobile: mobileCardImages[6],
        },
        {
            author: {
                name: `${revEight.name}`,
                site: 'bambinibg.com',
                img: authors[7],
                logo: author_logo_8_black,
                logo_theme_white: author_logo_8_white
            },
            description: `${revEight.description}`,
            img_pc: cardImages[7],
            img_mobile: mobileCardImages[7],
        },
        {
            author: {
                name: `${revNine.name}`,
                site: 'scrapbooky.net',
                img: authors[8],
                logo: author_logo_9_black,
                logo_theme_white: author_logo_9_white
            },
            description: `${revNine.description}`,
            img_pc: cardImages[8],
            img_mobile: mobileCardImages[8],
        },
        {
            author: {
                name: `${revTen.name}`,
                site: 'gabrielleshop.eu',
                img: authors[9],
                logo: author_logo_10_black,
                logo_theme_white: author_logo_10_white
            },
            description: `${revTen.description}`,
            img_pc: cardImages[9],
            img_mobile: mobileCardImages[9],
        },
    ]

    // Preload images
    useEffect(() => {
        cardImages.forEach(src => new Image().src = src);
    }, []);

    return (
        <div className='reviews' id='reviews' data-section>
            <div className='reviews__content'>
                <Swiper
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    modules={[Mousewheel, Navigation, Pagination]}
                    preventInteractionOnTransition={true}
                    className="reviews__slides reviews__slides__pagination"
                // onInit={(ev) => {
                //     set_my_swiper(ev)
                // }}
                >
                    {
                        REVIEWS.map((slide, i) => (
                            <SwiperSlide className="reviews__slide" key={`review-slide-${i}`}>
                                <ReviewSlide index={i + 1} {...{ slide, theme }} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default Reviews