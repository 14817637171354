import { useContext } from 'react';
import './Partners.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { ThemeContext } from '../../../assets/contexts/themeContext';
import { THEME_LIGHT_TYPE } from '../../../assets/utils/constants';

// This will import all images from the specified directory dynamically
const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1]);
            const numB = parseInt(b.match(/item(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const images = importAll(require.context('../../../assets/images/main-partners', false, /item\d+\.webp$/));

const importAllBlack = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/itemBlack(\d+)/)[1]);
            const numB = parseInt(b.match(/itemBlack(\d+)/)[1]);
            return numA - numB;
        })
        .map(requireContext);

const imagesBlack = importAllBlack(require.context('../../../assets/images/main-partners/black', false, /itemBlack\d+\.webp$/));

function Partners({ }) {
    const { theme } = useContext(ThemeContext);
    const { main } = useContext(TranslationContext);
    const { mainPartners } = main;

    return (
        <div className='main-partners'>
            <p className='main-partners__title'>{mainPartners.mainTitle}</p>
            <p className='main-partners__subtitle'>{mainPartners.mainSubTitle}</p>
            <div className='main-partners__cards'>
                {theme === THEME_LIGHT_TYPE ?
                    images.map((item, i) => {
                        return (
                            <img src={item} alt='' className='main-partmers__card'></img>
                        )
                    })
                    :
                    imagesBlack.map((item, i) => {
                        return (
                            <img src={item} alt='' className='main-partmers__card'></img>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Partners